import lodash from "lodash";
import React, { useState } from "react";
import { Create, Datagrid, DateField, Edit, EditButton, Error, Filter, List, Loading, Pagination, RadioButtonGroupInput, ReferenceField, ReferenceInput, required, Resource, SelectInput, Show, ShowButton, ShowController, SimpleForm, SimpleShowLayout, TextField, TextInput, useQuery } from "react-admin";
import { CustomTextField } from "./invitations";


const statusTypes = {
  new: "New",
  pending: "Pending",
  inProgress: "In Progress",
  sent: "Sent",
  error: "Error",
  perpetual: "Perpetual"
};

const statusTypesArray = Object.values(statusTypes).map((item) => ({ id: item, name: item }));

const InvitationsList = (campaignId) => {
  const [page, setPage] = useState(1);
  const perPage = 10;
  const { data, total, error, loading } = useQuery(
    {
      type: 'getList',
      resource: 'invitations',
      payload: {
        pagination: {
          page: page,
          perPage: perPage
        },
        filter: {
          campaign: campaignId
        }
      }
    }
  );
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  const linkToInvitations = encodeURI('/admin/#/invitations?displayedFilters= ' +
    `{"campaign":true}&filter={"campaign":"${campaignId}"}` +
    '&order=ASC&page=1&perPage=10&sort=id');
  return (
    <React.Fragment>
      <h2>Lista de Invitaciones</h2>
      <Datagrid
        data={lodash.keyBy(data, 'id')}
        ids={data.map(({ id }) => id)}
        currentSort={{ field: 'id', order: 'ASC' }}
      >
        <TextField label="Invitación" source="id" sortable={false} />
        <TextField label="Provider" source="provider" sortable={false} />
        <CustomTextField label="Token para push notifications" source="receiverAccount" sortable={false} />
        <TextField label="Token" source="token" sortable={false} />
        <TextField label="Status de envío" source="sendStatus" sortable={false} />
        <ReferenceField label="Application" source="application" reference="applications">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceInput label="Unsubscribe Template" source="unsubscribeTemplate" reference="unsubscribe_template">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Datagrid>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
      />
      <a href={linkToInvitations}>Ir a Lista de invitaciones</a>
    </React.Fragment>
  );
};

const CampaignFilter = (props) => (
  <Filter {...props}>
    <SimpleForm validate={values => {
      const errors = {};
      if (!values.pushNotificationTemplate && !values.emailTemplate) {
        errors.pushNotificationTemplate = "Al menos un template es obligatorio";
        errors.emailTemplate = "Al menos un template es obligatorio";
      }
      return errors
    }}>
      <TextInput label="Search" source="q" alwaysOn />
      <TextInput label="Id" source="id" />
      <TextInput label="Campaña" source="name" />
      <SelectInput label="Status de envío" source="sendStatus" choices={statusTypesArray} />
      <ReferenceInput label="Push Notification Template" source="pushNotificationTemplate" reference="push_notification_template">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Email Template" source="emailTemplate" reference="email_template">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Application" source="application" reference="applications">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Filter>
);

const CampaignList = (props) => (
  <List {...props} filters={<CampaignFilter />}>
    <Datagrid>
      <TextField label="Id" source="id" />
      <TextField label="Campaña" source="name" />
      <TextField label="Status de envío" source="sendStatus" />
      <ReferenceField label="Push Notification Template" source="pushNotificationTemplate" reference="push_notification_template">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Email Template" source="emailTemplate" reference="email_template">
        <TextField optionText="name" />
      </ReferenceField>
      <ReferenceField label="Unsubscribe Template" source="unsubscribeTemplate" reference="unsubscribe_template">
        <TextField optionText="name" />
      </ReferenceField>
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const CampaignShow = (props) => {
  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show {...props}  {...controllerProps}>
          <SimpleShowLayout>
            <TextField label="Id" source="id" />
            <TextField label="Campaña" source="name" />
            <TextField label="Status de envío" source="sendStatus" />
            {controllerProps.record && controllerProps.record.sendStatus === statusTypes.sent &&
              <DateField label="Fecha y hora de envío" source="updatedAt" showTime />
            }
            <ReferenceField label="Push Notification Template" source="pushNotificationTemplate" reference="push_notification_template">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Email Template" source="emailTemplate" reference="email_template">
              <TextField optionText="name" />
            </ReferenceField>
            <ReferenceField label="Application" source="application" reference="applications">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Unsubscribe Template" source="unsubscribeTemplate" reference="unsubscribe_template">
              <TextField optionText="name" />
            </ReferenceField>
            {controllerProps.record && InvitationsList(controllerProps.record.id)}
          </SimpleShowLayout>
        </Show>
      }
    </ShowController>
  );
};

const CampaignEdit = (props) => (
  <Edit {...props}>
    <SimpleForm validate={values => {
      const errors = {};
      if (!values.pushNotificationTemplate && !values.emailTemplate) {
        errors.pushNotificationTemplate = "Al menos un template es obligatorio";
        errors.emailTemplate = "Al menos un template es obligatorio";
      }
      return errors
    }}>
      <TextInput disabled label="Id" source="id" validate={[required()]} />
      <TextInput label="Campaña" source="name" />
      <ReferenceInput label="Push Notification Template" source="pushNotificationTemplate" reference="push_notification_template" >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput label="Email Template" source="emailTemplate" reference="email_template" >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Application" source="application" reference="applications">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Unsubscribe Template" source="unsubscribeTemplate" reference="unsubscribe_template">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit >
);

const CampaignCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={values => {
      const errors = {};
      if (!values.pushNotificationTemplate && !values.emailTemplate) {
        errors.pushNotificationTemplate = "Al menos un template es obligatorio";
        errors.emailTemplate = "Al menos un template es obligatorio";
      }
      return errors
    }}>
      <TextInput label="Campaña" source="name" validate={[required()]} />
      <ReferenceInput label="Push Notification Template" source="pushNotificationTemplate" reference="push_notification_template">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput label="Email Template" source="emailTemplate" reference="email_template">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Application" source="application" reference="applications">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Unsubscribe Template" source="unsubscribeTemplate" reference="unsubscribe_template">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <RadioButtonGroupInput source="sendStatus" label="Tipo" choices={[
        { id: statusTypes.new, name: 'Regular' },
        { id: statusTypes.perpetual, name: 'Perpetua' }
      ]} />
    </SimpleForm>
  </Create>
);

export default () => <Resource name="campaigns" show={CampaignShow} create={CampaignCreate} edit={CampaignEdit} list={CampaignList} />;
