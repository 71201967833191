import React from "react";
import { Create, Datagrid, DateField, Edit, EditButton, Filter, List, PasswordInput, Resource, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from "react-admin";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="username" />
  </Filter>
);

const UserList = (props) => (
  <List {...props} filters={<UserFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <DateField source="updatedAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="username" />
      <TextField source="firstname" />
      <TextField source="lastname" />
    </SimpleShowLayout>
  </Show>
);

const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="username" />
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Edit>
);

const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" />
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Create>
);

export default () => <Resource name="users" show={UserShow} create={UserCreate} edit={UserEdit} list={UserList} />;
