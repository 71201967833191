import React from "react";
import { Create, Datagrid, Edit, EditButton, Filter, List, required, Resource, Show, ShowButton, ShowController, SimpleForm, SimpleShowLayout, TextField, TextInput } from "react-admin";

const PushNotificationTemplateFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Id" source="id" />
    <TextInput label="Nombre" source="name" />
    <TextInput label="Título" source="title" />
    <TextInput label="Mensaje" source="message" />
  </Filter>
);

const PushNotificationTemplateList = (props) => (
  <List {...props} filters={<PushNotificationTemplateFilter />}>
    <Datagrid>
      <TextField label="Id" source="id" />
      <TextField label="Nombre" source="name" />
      <TextField label="Título" source="title" />
      <TextField label="Mensaje" source="message" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const PushNotificationTemplateShow = (props) => {
  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show {...props}  {...controllerProps}>
          <SimpleShowLayout>
            <TextField label="Id" source="id" />
            <TextField label="Nombre" source="name" />
            <TextField label="Título" source="title" />
            <TextField label="Mensaje" source="message" />
          </SimpleShowLayout>
        </Show>
      }
    </ShowController>
  );
};

const PushNotificationTemplateEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" validate={[required()]} />
      <TextInput label="Nombre" source="name" />
      <TextInput label="Título" source="title" />
      <TextInput label="Mensaje" source="message" />
    </SimpleForm>
  </Edit >
);

const PushNotificationTemplateCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Nombre" source="name" validate={[required()]} />
      <TextInput label="Título" source="title" validate={[required()]} />
      <TextInput label="Mensaje" source="message" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default () => <Resource name="push_notification_template" show={PushNotificationTemplateShow} create={PushNotificationTemplateCreate} edit={PushNotificationTemplateEdit} list={PushNotificationTemplateList} />;
