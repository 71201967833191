import React from "react";
import { Create, Datagrid, Edit, EditButton, Filter, List, required, Resource, Show, ShowButton, ShowController, SimpleForm, SimpleShowLayout, TextField, TextInput } from "react-admin";

const EmailTemplateFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Id" source="id" />
    <TextInput label="Nombre" source="name" />
    <TextInput label="Cuerpo del mail" source="body" multiline />
    <TextInput label="De" source="from" />
    <TextInput label="Asunto" source="subject" />
  </Filter>
);

const EmailTemplateList = (props) => (
  <List {...props} filters={<EmailTemplateFilter />}>
    <Datagrid>
      <TextField label="Id" source="id" />
      <TextField label="Nombre" source="name" />
      <TextField label="De" source="from" />
      <TextField label="Asunto" source="subject" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const EmailTemplateShow = (props) => {
  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show {...props}  {...controllerProps}>
          <SimpleShowLayout>
            <TextField label="Id" source="id" />
            <TextField label="Nombre" source="name" />
            <TextField label="Cuerpo del mail" source="body" />
            <TextField label="De" source="from" />
            <TextField label="Asunto" source="subject" />
          </SimpleShowLayout>
        </Show>
      }
    </ShowController>
  );
};

const EmailTemplateEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" validate={[required()]} />
      <TextInput label="Nombre" source="name" validate={[required()]} />
      <TextInput label="Cuerpo del mail" source="body" validate={[required()]} multiline />
      <TextInput label="De" source="from" validate={[required()]} />
      <TextInput label="Asunto" source="subject" validate={[required()]} />
    </SimpleForm>
  </Edit >
);

const EmailTemplateCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Nombre" source="name" validate={[required()]} />
      <TextInput label="Cuerpo del mail" source="body" validate={[required()]} multiline />
      <TextInput label="De" source="from" validate={[required()]} />
      <TextInput label="Asunto" source="subject" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default () => <Resource name="email_template" show={EmailTemplateShow} create={EmailTemplateCreate} edit={EmailTemplateEdit} list={EmailTemplateList} />;
