import React from "react";
import { Create, Datagrid, DateField, Edit, EditButton, Filter, List, ReferenceField, ReferenceInput, required, Resource, SelectInput, Show, ShowButton, ShowController, SimpleForm, SimpleShowLayout, TextField, TextInput } from "react-admin";


export const CustomTextField = ({ source, record = {} }) => <span>{record[source].length > 20 ? record[source].slice(0, 20) + "..." : record[source]}</span>;

const statusTypes = {
  new: "New",
  sent: "Sent",
  error: "Error"
};

const statusTypesArray = Object.values(statusTypes).map((item) => ({ id: item, name: item }));
const channelTypes = [
  { id: "EMAIL", name: "Email" },
  { id: "FCM", name: "FCM" }
]

let wasError = false;
function JSONParse(src) {
  wasError = false
  try {
    return JSON.parse(src);
  } catch (error) {
    wasError = true;
    if (error instanceof SyntaxError) {
      return src
    } else {
      throw error;
    }
  }
}
const JSONStringify = (src) => !wasError ? JSON.stringify(src, null, 2) : src;

const InvitationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Invitación" source="id" />
    <TextInput label="Channel" source="channel" />
    <TextInput label="Reciber account" source="receiverAccount" />
    <TextInput label="Token" source="token" />
    <SelectInput label="Status de envío" source="sendStatus" choices={statusTypesArray} />
    <ReferenceInput label="Campaña" source="campaign" reference="campaigns" validate={[required()]}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Template data" source="data" style={{ width: '100%' }} format={config => JSONStringify(config)} parse={config => JSONParse(config)} className="monospace-text" multiline />
  </Filter>
);

const InvitationList = (props) => (
  <List {...props} filters={<InvitationFilter />}>
    <Datagrid>
      <TextField label="Invitación" source="id" />
      <ReferenceField label="Campaña" source="campaign" reference="campaigns">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Channel" source="channel" />
      <CustomTextField label="Reciber account" source="receiverAccount" />
      <TextField label="Token" source="token" />
      <TextField label="Status de envío" source="sendStatus" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);


const InvitationShow = (props) => {
  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show {...props}  {...controllerProps}>
          <SimpleShowLayout>
            <TextField label="Invitación" source="id" />
            <ReferenceField label="Campaña" source="campaign" reference="campaigns">
              <TextField source="name" />
            </ReferenceField>
            <TextField label="Channel" source="channel" />
            <TextField label="Reciber account" source="receiverAccount" />
            <TextField label="Token" source="token" />
            <TextField label="Status de envío" source="sendStatus" />
            {controllerProps.record && controllerProps.record.sendStatus === statusTypes.sent &&
              <DateField label="Fecha y hora de envío" source="updatedAt" showTime />
            }
            <TextField label="Template data" source="data" />
          </SimpleShowLayout>
        </Show>
      }
    </ShowController>
  );
};


const InvitationEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Invitación" source="id" />
      <SelectInput label="Channel" source="channel" choices={channelTypes} validate={[required()]} />
      <TextInput label="Reciber account" source="receiverAccount" />
      <TextInput label="Token" source="token" />
      <ReferenceInput label="Campaña" source="campaign" reference="campaigns" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Template data" source="data" style={{ width: '100%' }} format={config => JSONStringify(config)} parse={config => JSONParse(config)} className="monospace-text" multiline />
    </SimpleForm>
  </Edit >
);

const InvitationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput label="Channel" source="channel" choices={channelTypes} validate={[required()]} />
      <TextInput label="Reciber account" source="receiverAccount" />
      <TextInput label="Token" source="token" />
      <ReferenceInput label="Campaña" source="campaign" reference="campaigns" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Template data" source="data" style={{ width: '100%' }} format={config => JSONStringify(config)} parse={config => JSONParse(config)} className="monospace-text" multiline />
    </SimpleForm>
  </Create>
);

export default () => <Resource name="invitations" show={InvitationShow} create={InvitationCreate} edit={InvitationEdit} list={InvitationList} />;
