import React from "react";
import { Admin, fetchUtils } from "react-admin";
import authProvider, { ACCESS_TOKEN_ITEM } from './authProvider';
import crudProvider from './crudProvider';
import applications from "./resources/applications";
import campaigns from './resources/campaigns';
import emailTemplates from './resources/emailTemplate';
import invitations from './resources/invitations';
import senderAccounts from "./resources/providerAccounts";
import providers from './resources/providers';
import pushNotificationTemplates from './resources/pushNotificationTemplate';
import unsubscribeTemplate from './resources/unsubscribeTemplate';
import users from './resources/users';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem(ACCESS_TOKEN_ITEM);
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = crudProvider(process.env.APP_URL, httpClient);

const App = () => (
  <Admin authProvider={authProvider} dataProvider={dataProvider}>
    {users()}
    {pushNotificationTemplates()}
    {emailTemplates()}
    {unsubscribeTemplate()}
    {campaigns()}
    {invitations()}
    {applications()}
    {senderAccounts()}
    {providers()}
  </Admin>
);
export default App;
