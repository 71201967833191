import React from "react";
import { Create, Datagrid, Edit, EditButton, Filter, List, required, Resource, Show, ShowButton, ShowController, SimpleForm, SimpleShowLayout, TextField, TextInput } from "react-admin";

const UnsubscribeTemplateFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Id" source="id" />
    <TextInput label="Nombre" source="name" />
  </Filter>
);

const UnsubscribeTemplateList = (props) => (
  <List {...props} filters={<UnsubscribeTemplateFilter />}>
    <Datagrid>
      <TextField label="Id" source="id" />
      <TextField label="Nombre" source="name" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const UnsubscribeTemplateShow = (props) => {
  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show {...props}  {...controllerProps}>
          <SimpleShowLayout>
            <TextField label="Id" source="id" />
            <TextField label="Nombre" source="name" />
            <TextField label="Cuerpo" source="html" />
          </SimpleShowLayout>
        </Show>
      }
    </ShowController>
  );
};

const UnsubscribeTemplateEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" validate={[required()]} />
      <TextInput label="Nombre" source="name" validate={[required()]} />
      <TextInput label="Cuerpo" source="html" validate={[required()]} multiline />
    </SimpleForm>
  </Edit >
);

const UnsubscribeTemplateCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Nombre" source="name" validate={[required()]} />
      <TextInput label="Cuerpo" source="html" validate={[required()]} multiline />
    </SimpleForm>
  </Create>
);

export default () => <Resource name="unsubscribe_template" show={UnsubscribeTemplateShow} create={UnsubscribeTemplateCreate} edit={UnsubscribeTemplateEdit} list={UnsubscribeTemplateList} />;
