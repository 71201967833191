import React from "react";
import { Create, Datagrid, Edit, EditButton, Filter, List, ReferenceField, ReferenceInput, Resource, SelectArrayInput, Show, ShowButton, ShowController, SimpleForm, SimpleShowLayout, TextField, TextInput } from "react-admin";
const ApplicationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" />
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Name" source="name" />
    <ReferenceInput label="Provider accounts" source="providerAccounts" reference="provider-accounts">
      <SelectArrayInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ApplicationList = (props) => (
  <List {...props} filters={<ApplicationFilter />}>
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <ReferenceField label="Provider accounts" source="providerAccounts" reference="provider-accounts">
        <TextField source="name" />
      </ReferenceField>
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const ApplicationShow = (props) => {
  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show {...props}  {...controllerProps}>
          <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Token" source="token" />
            <TextField label="Api Token" source="apiToken" />
            <ReferenceField label="Provider accounts" source="providerAccounts" reference="provider-accounts">
              <TextField source="name" />
            </ReferenceField>
          </SimpleShowLayout>
        </Show>
      }
    </ShowController>
  );
};

const ApplicationEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField label="ID" source="id" />
      <TextInput label="Name" source="name" />
      <ReferenceInput label="Provider accounts" source="providerAccounts" reference="provider-accounts">
        <SelectArrayInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit >
);

const ApplicationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Name" source="name" />
      <ReferenceInput label="Provider accounts" source="providerAccounts" reference="provider-accounts">
        <SelectArrayInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default () => <Resource name="applications" show={ApplicationShow} create={ApplicationCreate} edit={ApplicationEdit} list={ApplicationList} />;
