import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  List,

  ReferenceInput,
  Resource,
  SelectInput,
  Show,
  ShowButton,
  ShowController,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  NumberInput
} from "react-admin";

const ProviderAccountFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Name" source="name" />
    <ReferenceInput label="Provider" source="provider_id" reference="providers">
      <SelectInput optionText="id" />
    </ReferenceInput>
  </Filter>
);

const ProviderAccountList = (props) => (
  <List {...props} filters={<ProviderAccountFilter />}>
    <Datagrid>
      <TextField label="Name" source="name" />
      <TextField label="Provider" source="provider_id" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const ProviderAccountShow = (props) => {
  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show {...props}  {...controllerProps}>
          <SimpleShowLayout>
            <TextField label="Name" source="name" />
            <TextField label="Provider" source="provider_id" />
            <TextField label="Config" source="config" />
          </SimpleShowLayout>
        </Show>
      }
    </ShowController>
  );
};

const ConfigEdit = () => (
  <FormDataConsumer>
    {
      ({ formData, ...rest }) => {
        const format = key => (config = formData.config || {}) => config[key];
        const parse = key => value => ({ ...formData.config, [key]: value });

        if (formData.provider_id === 'Firebase') {
          return (
            <TextInput style={{ width: '100%' }} className="monospace-text" format={config => JSON.stringify(config, null, 2)} parse={config => JSON.parse(config)} label="Config" source="config" multiline />
          );
        } else if (formData.provider_id === 'Amazon SES') {
          return (
            <>
              <TextInput style={{ width: '100%' }} label="accessKeyId" source="config" format={format("accessKeyId")} parse={parse("accessKeyId")} />
              <TextInput style={{ width: '100%' }} label="secretAccessKey" source="config" format={format("secretAccessKey")} parse={parse("secretAccessKey")} />
              <TextInput style={{ width: '100%' }} label="region" source="config" format={format("region")} parse={parse("region")} />
              <TextInput style={{ width: '100%' }} label="Configuration Set" source="config" format={format("configurationSet")} parse={parse("configurationSet")} />
              <NumberInput label="Send Rate" source="config" format={format("sendRate")} parse={parse("sendRate")} min={1} step={1} />
            </>
          );
        } else {
          return null;
        }
      }
    }
  </FormDataConsumer>
)

const ProviderAccountEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Name" source="name" />
      <ReferenceInput label="Provider" source="provider_id" reference="providers">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ConfigEdit />
    </SimpleForm>
  </Edit >
);

const ProviderAccountCreate = (props) => (
  <Create {...props}>
    <SimpleForm >
      <TextInput label="Name" source="name" />
      <ReferenceInput label="Provider" source="provider_id" reference="providers">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ConfigEdit />
    </SimpleForm>
  </Create>
);

export default () => <Resource name="provider-accounts" show={ProviderAccountShow} create={ProviderAccountCreate} edit={ProviderAccountEdit} list={ProviderAccountList} />;
