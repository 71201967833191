import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';

export const ACCESS_TOKEN_ITEM = 'access_token';

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${process.env.APP_URL}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    const { access_token } = await response.json();
    localStorage.setItem(ACCESS_TOKEN_ITEM, access_token);
  } else if (type === AUTH_LOGOUT) {
    localStorage.removeItem(ACCESS_TOKEN_ITEM);
  } else if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(ACCESS_TOKEN_ITEM);
      return Promise.reject();
    }
  } else if (type === AUTH_CHECK) {
    return localStorage.getItem(ACCESS_TOKEN_ITEM) ? Promise.resolve() : Promise.reject();
  }
}
